//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";
@import "./pages/Blog/blog.scss";


.fontRoboto127 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}

.drop-down-custom {
  color: #5664d2;
}

.reset {
  // position: absolute;
  // width: 104px;
  // height: 37px;
  background: #0ab38c !important
}

.search-icon-color {
  background-color: #5664d2;
}

.table-row-collapse {
  width: 100%;
  background-color: #f1f5f7;
  padding-top: 20px;
  margin-bottom: 20px;
}

.t-cell td {
  width: 5%;
}

.colsp {
  width: 19%;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}


.last {
  padding-top: 1rem;
  margin-left: 1rem;
}

.last div {
  padding: 0px;
}

.close-btn {
  position: relative;
  left: 19%;
  margin-top: 2%;
}


Label {
  font-family: "Roboto";
}

.req {
  color: red;
}

.err {
  font-size: small;
  color: red;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.required_field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.align-center {
  text-align: center !important;
}

.underline {
  text-decoration: underline !important;
}


.scroll-hide {
  overflow-y: hidden !important;
}



.noDataRecord {
  height: inherit;
  font-size: 18px;
  font-weight: 500;
}



.dropDownDelete {
  margin-left: 20px !important;
}

.button-wide {
  width: 125px;
  height: 37px;
}


.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.css-6q0nyr-Svg {
  color: "#2800FC" !important;
}
#contentCard {
  padding: 0px;
  margin-left: 85px;
  transition: margin-left 0.5s;
}

//for removing the bottom header from the dataTable
tbody+thead {
  display: none;
}

.page-content {
  font-family: "Roboto", sans-serif;
}

.searchBox {
  background-color: #f1f5f7 !important;
}

.iconSize {
  font-size: 0.7em;
}


@media (min-width: 960px) and (max-width: 1224px) {
  .reset {
    width: 100%;
  }
}

.form-check {
  width: 100%;
  display: block;
  max-width: -webkit-fill-available;
}


.quickStylesBar {
  .input-group>.form-control {
    height: 22px !important;
    padding-left: 5px !important;
    border-style: none !important;
  }

  .input-group>.form-control:hover,
  .input-group>.form-control:focus {
    border-style: none !important;
    // height: 22px !important;
  }

  .input-border:hover,
  .input-border:focus {
    border: 2px solid #0000ff !important;
    // height: 26px !important;
    z-index: 100;

    .input-group {
      height: 24px !important;
    }
  }

  .input-group {
    width: 95% !important;
  }

  .input-border {
    height: 26px !important;
    border: 0.5px solid #aeaeae !important;
    border-radius: 5px !important;
  }

  .input-line {
    margin-top: 3px;
    height: 20%;
    // z-index: 1;
    border-left: 2px solid #0000ff;
  }

  .span-img {
    width: 15px;
    height: 12px;
    // z-index: 1;
    left: 50% !important;
    cursor: pointer;
  }
}

.dropdown {
  .dropdown-content {
    right: 40px;
    bottom: 0px;
    position: absolute;
  }
}

.tooltipIcon {
  position: relative;
  display: inline-block;
}

.tooltipIcon .tooltipIcontext1 {
  visibility: hidden;
  width: 70px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 31%;
  left: 7%;
  margin-left: -60px;
}

.tooltipIcon .tooltipIcontext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}


.tooltipIcon:hover .tooltipIcontext1 {
  visibility: visible;
}

.mobile-toggle {
  display: none;
}
.desktop-reset {
  display: inline;
}

.mobile-reset {
  display: none;
}

.desktopDisplay {
  display: flex;
}


.tile-text1 {
  padding-top: 26px;
  height: 70px !important;
  font-weight: 400;
  color: #0c0c0c;
}

.hrLineProfile {
  width: 70%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #B9B9B9;
}


// ******* mobile break points ****



@media (max-width: 640px) {
 

  .MobilefontRoboto127 {
    font-family: Roboto;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
  }

  .MobilefontRoboto124 {
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }





  .fontRoboto {
    font-size: 20px;
    white-space: nowrap !important;
  }

 
  .desktopDisplay {
    display: none;
  }

  .mobileDisplay {
    display: block;
  }
  .textALign {
    padding-left: 28%;
    text-align: start;
  }

  .desktop-reset {
    display: none;
  }

  .mobile-reset {
    display: inline;
    width: 45px;
    height: 26px;

    .mobile-rest-img {
      height: 19px;
      margin-top: -5px;
    }
  }

  .textALign {
    padding-left: 0px;
    text-align: start;
  }

  .textEcllipse {
    display: block;
    position: absolute;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .navbar-header-new-top {
    padding-bottom: 45px !important;
  }

  .navbar-header-new {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    // box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-toggle {
    display: flex;
    padding-top: 2px;
    cursor: pointer;
  }

  #mySidebar {
    display: none;
  }

  #my-sidebar-mobile {
    display: block;
    width: 300px;
  }

  #contentCard {
    margin-left: 0px !important;
    // padding: 0px 25px;
    margin-right: 0px;
  }

}

@media (max-width: 577px) {

  .imageIconS {
    padding-left: 23%;
  }
}

@media (max-width: 480px) {

  .imageIconS {
    padding-left: 22%;
  }

  .fontRoboto {
    font-size: 15px;
    white-space: nowrap !important;
  }


  .navbar-header-new-top {
    .span-font-roboto {
      font-size: 9px;
      white-space: nowrap;
    }
  }
  .MobileflesParent {
    margin-left: -36px;
    position: relative;
    width: 95%;
  }


  .MobileProfileParent {
    width: 150px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .profile-name {
      font-size: 10px !important;
    }
  }

}


@media (max-width: 380px) {
  .MobileProfileParent {
    width: 100px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .profile-name {
      font-size: 10px !important;
    }
  }

}

//new hybrid
.tableBtns Button {
  min-width: auto;
  padding: 2px 10px;
  color: #0c0c0c;
  font-weight: 400;
  font-style: normal;
}

.lightBtn {
  color: #5e5e5e !important;
  background: #fff;
  min-width: 80px;
}

//login
.loginDivCss {
  background: linear-gradient(to left, rgb(120, 208, 120), white);
  background-position: 50%;
  background-size: cover;
  color: #5e5e5e;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;

  .loginLogRight {
    background: #fff;
    padding: 40px 80px;
    text-align: center;
    margin: auto;
    margin-top: 5%;
    border-radius: 25px;
    box-shadow:5px 5px 13px 2px rgba(191, 225, 233, 0.5); ;
  }
}
//common form focus
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0px 5px 7px 0px rgba(191, 225, 233, 0.5);
  }

.loginLogRight h2 {
  color: #980033;
  font-size: 26px;
}

.footDivStyle {
  bottom: 0;
  padding: 0 25px;
  width: 100%;
  color: #212529;
  font-size: 15px;
}

.buttonDivStyle {
  background: #5e5e5e !important;
  color: #fff !important;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  padding: 10px;
}
//login

//view Product Image
.squareImg {
  height: 475px;
  width: 775px;
  display: block;
  img {
    max-width: 100%;
    max-height: 75%;
    display: block !important;
    object-fit: cover !important;

}
}
.viewModel {
  .model-content,
  .modal-body{
    // max-height: calc(100vh - 0px);
  
    img{
      max-height: 650px;
    }
  }  
  .modal-backdrop {
    width:100%;
    height: 100%;
    background-color: rgb(103, 125, 121);
}
}
////view product
//slug

.content{
  max-width: 90%;
  margin: auto;
  background: #fbfcfa;
  padding: 10px;
}

//login
@media (max-width: 575.98px) {
  .loginDivCss .loginLogRight {
    padding: 40px 20px !important;
    width: 80%;
  }
}





//search bar
@media only screen and (min-width: 1280px) {

  .quickStylesBar {
    width: 95%;
  }

}
//content page
@media only screen and (min-width: 960px) and (max-width: 1197px) { 
    #contentCard {
      flex-basis: 100% !important;
      max-width: 93% !important;
      padding: 0px;
    }
    .reset{
      width: 170%;
    }
}