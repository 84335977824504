.mainExpandedStyle {
  position: relative;
  background-color:#d7e4ec;
  /* color: black; */
  height: 100%;
  width: 225px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
  border: 1px solid transparent;
}
.mainExpandedStyle .mainLink:hover {
  /* background-color: white; */
  transform: scale(1.025, 1.025);
  border: 1px solid transparent;
  border-color: #ffffff;
  background: linear-gradient(-35deg, #606,rgb(78,145,253),#603) ;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
}


.mainExpandedStyle .subMenuLinkDiv:hover {
  /* background-color: white; */
  transform: scale(1.025, 1.025);
  border: 1px solid transparent;
  border-color:#a8b8d0;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
}

.mainExpandedStyles {
  position: relative;
  background-color:#d7e4ec !important;
  color: black;
  height: 100%;
  width: 82px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  max-width: 82px;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
  
}



.mainLogoStyle {
  position: absolute;
  height: 55px;
  width: 80%;
  margin: auto;
  top: 22px;
  left: 10px;
}

.mainLogoStyleMobile{
  position: absolute;
    height: 109px;
    width: 75%;
    margin: auto;
    top: 4%;
    left: 12%;
    object-fit: fill;
}

.mainLogoStyleMini {
  position: absolute;
  height: 51px;
  width: 75%;
  margin: auto;
  top: 27px;
  left: 10px;
}

.logoSepartorStyle {
  position: absolute;
  border: 0.1px solid #f2f2f2;
  margin: auto;
  width: 92%;
  top: 104px;
  left: 7px;
}
.logoSepartorStyleMobile {
  position: absolute;
  border: 0.5px solid #ffffff;
  margin: auto;
  width: 92%;
  top: 189px;
  left: 4%;
}
.closeIconStyles{
  position: absolute;
  right: -1%;
  top: -1%;
  font-size: 40px;
  width: 30px;
}
.linksSectionMobile{
      position: absolute;
    top: 17%;
    width: 90%;
    margin: 8%;
    margin-top: 10%;
    cursor: pointer;
}

.linksSection {
  position: absolute;
  top: 170px;
  width: 90%;
  margin: 8%;
  margin-top: 10%;
  cursor: pointer;
}

.linkSection {
  /* margin-left: 7%; */
  /* border: 1px solid red; */
  /* padding: 5% 0%; */
  margin-right: 10%;
  margin-top: 7%;
}

.mainLink {
  height: 50px;
  padding: 1%;
}

.mainLinkName {
  margin-left: 8%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  /* color: #1e1c1c; */
  cursor: pointer;
}
.mainLink:hover {
  color:#ffffff;
}


.activeLinkSection {
  background-color:#f8f9fa;
  border-radius: 5px;
}

.subMenu {
  margin-left: 32%;
  margin-top: 3%;
}
.subMenuReport {
  margin-left: 16%;
  margin-top: 6%;
}

.subMenuLinkName {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  color: #0c0a0a;
}

.hideSubMenu {
  display: none;
}

.displaySubMenu {
  display: block;
}

@media screen and (max-height: 450px) {
  #sidebar {
    padding-top: 15px;
  }
  #sidebar a {
    font-size: 18px;
  }
}



.mainExpandedStyle .mainLinkHelp:hover {
  /* background-color: white; */
  transform: scale(1.025, 1.025);
  border: 1px solid transparent;
  border-color: #e8e8e8;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
}

.imgMini{
  width: 30px;
  height: 30px;
  margin: 15%;
}
