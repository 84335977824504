
.page-topbar-new {
    /* position: relative;
    height: 85px !important;
    width: 100%;
    left:0%;
    background-color: #FFFFFF !important; */
    height: 66px !important;
    width: 100%;
    left: 5%;
    background-color: #FFFFFF !important;
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    z-index: 1;
    /* position: fixed;
    z-index: 999; */
    display: flex;
    right: 61px;
  }
  .navbar-header-new {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 10px;
    width: 100%;
  }
  .breadactive{
    color: #2800FC;
  }
  .breadcrumb-active:hover,.breadcrumb-active:focus{
    text-decoration: underline;
    cursor: pointer;
  }
  .linkText {
    color: #505d69 !important;
  }
  .profile-name{
    font-family:Roboto;
    font-weight: 400;
    font-size: 15px;
    color: #0C0C0C;
    line-height: 14.08px;
    font-style: normal;
  }
  .profile-chervon{
    width: 10.26px;
    height: 5.13px;
    padding-top: 0px;
    color:#2800FC ;
    font-size: 20px;
  }
  .span-font-roboto{
    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 640px) {
  .profile-chervon{
    display: none
  }
}