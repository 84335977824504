.blogModel {
  position: relative;
  

    .modal-content{
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 24px;
      box-shadow: 6px 14px 19px -1px rgba(10,10,9,0.75);
      -webkit-box-shadow: 6px 14px 19px -1px rgba(10,10,9,0.75);
      -moz-box-shadow: 6px 14px 19px -1px rgba(10,10,9,0.75); 
      img{
        max-width: 100%;
        height:100%;
        padding: 0px;
        margin: 0px;               
      }
    }     
    .modal-backdrop {
      width: 810vw;
      height: 823vh;
      background-color: rgb(103, 125, 121);
  }
    h2 {
    font-size: 1.5rem;
    color: black;
    font-weight: bolder;
  }
  }

  .card-info {
    position: relative;
    padding: 0.45rem 1.25rem;
    transition: all 200ms ease-in-out;
    color: black;
    .card-about {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.45rem 0;
      transition: all 200ms ease-in-out;

      .card-tag {
        width: 60px;
        max-width: 100px;
        padding: 0.2rem 0.5rem;
        font-size: 12px;
        text-align: start;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: #505f79;
        color: #fff;     
      }
    }

    .card-title {
      z-index: 10;
      font-size: 1.1rem;
      padding-bottom: 0.55rem;
      transition: all 350ms ease-in-out;
    }

    .card-creator,.card-time {
      padding-bottom: 0px;
      transition: all 250ms ease-in-out;
      font-weight: bolder;  
      text-transform: uppercase;   
    }
  }


